import React from 'react';
import  Archive2023  from '../views/Archive2023';

const BlogNewsroomPage = () => {

return <Archive2023/>
};

export default BlogNewsroomPage;

